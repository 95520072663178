import http from '../http-common'

class MappingTokenService {
  getAll = () => http.get('/mapping-token')

  get = id => http.get(`/mapping-token/${id}`)

  create = data => http.post('/mapping-token', data)

  update = (id, data) => http.put(`/mapping-token/${id}`, data)

  delete = id => http.delete(`/mapping-token/${id}`)
}

export default new MappingTokenService()
