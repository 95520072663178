<template>
  <v-card>
    <v-card-title>Create Mapping Token</v-card-title>
    <v-form class="multi-col-validation">
      <v-card-text class="pt-5">
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="mappingToken.clientID"
              outlined
              label="Client ID"
              required
              :rules="[v => !!v || 'Client ID is required']"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="mappingToken.clientContact"
              outlined
              label="Client Contact"
              required
              :rules="[v => !!v || 'Client Contact is required']"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="mappingToken.username"
              outlined
              label="Username"
              required
              :rules="[v => !!v || 'Username is required']"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="mappingToken.password"
              :type="isPasswordVisible ? 'text' : 'password'"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              outlined
              label="Password"
              required
              :rules="[v => !!v || 'Password is required']"
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="mappingToken.elexysToken"
              outlined
              label="Token"
              required
              :rules="[v => !!v || 'Token is required']"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text>
        <v-btn
          color="primary"
          class="me-3 mt-3"
          @click.prevent="saveMappingToken"
        >
          Save
        </v-btn>
        <v-btn
          outlined
          class="mt-3"
          color="secondary"
          type="reset"
          @click.prevent="resetForm"
        >
          Cancel
        </v-btn>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import MappingTokenService from '../../services/MappingTokenService'

export default {
  data() {
    return {
      isPasswordVisible: false,
      mappingToken: {},
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
  methods: {
    saveMappingToken() {
      const data = {
        clientID: this.mappingToken.clientID,
        clientContact: this.mappingToken.clientContact,
        username: this.mappingToken.username,
        password: this.mappingToken.password,
        elexysToken: this.mappingToken.elexysToken,
      }
      MappingTokenService.create(data)
        .then(response => {
          this.$router.push({ name: 'mapping-token-details', params: { id: response.data.id } })
        })
        .catch(e => {
          console.log(e)
        })
    },
    resetForm() {
      this.$router.push({ name: 'mapping-token' })
    },
  },
}
</script>
